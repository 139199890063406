.videos-container {
    align-content: center;
    background-image: url('../../utils/images/components/fondo_two_components.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1%;
}

.title-videos {
    width: 35%;
    justify-items: center;
    height: 4rem;
    margin: 0% 0 3% 33%;
    background-color: rgba(255, 193, 7, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 900;
    align-content: center;
    font-family: var(--font-bold-italic);
}

.description-audio {
    margin: unset;
}

.frame-video{
    border-radius: 10px;
    align-self: center;
}

.seasons-container-videos {
    display: flex;
    justify-content: space-evenly;
    height: 14rem;
    padding-top: 4%;
}

.season-text-videos {
    height: 18rem;
    padding: 0 10% 0 10%;
    font-family: var(--font-light);
    color: rgba(255, 255, 255, $alpha: 1);
}

.container-season-1-videos p,
.container-season-2-videos p,
.container-season-3-videos p {
    margin: 6.5% 0 0 1.5%;
    position: absolute;
    font-family: var(--font-bold);
    color: rgba(255, 255, 255, $alpha: 1);
}

.container-season-1-videos img, 
.container-season-2-videos img,
.container-season-3-videos img {
    width: 10rem;
}

.container-season-1-videos p:hover, 
.container-season-2-videos p:hover,
.container-season-3-videos p:hover {
    cursor: pointer;
    transition: transform 0.1s ease-in-out; 
    transform: scale(1.2, 1.1); 
}