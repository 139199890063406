body {
    margin: 0;
    padding: 0;
}

:root {

    @font-face {
        font-family: 'poppins-bold';
        src: url('../utils/fonts/Poppins/Poppins-Bold.ttf');
    }

    @font-face {
        font-family: 'poppins-bold-italic';
        src: url('../utils/fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
    }
    
    @font-face {
        font-family: 'poppins-light';
        src: url('../utils/fonts/Poppins/Poppins-Light.ttf');
    }
    
    /* Sizes */
    
    /* Header Component */
    --header-width-container-size: 100%;
    --header-height-container-size: 9.5rem;
    --header-component-background-position: -0.5em;
    --header-component-section-paddin: 1.5rem;
    // --header-component-image-width: 11rem;
    --header-component-image-width: 100%;
    --header-component-image-height: 6rem;
    --header-component-image-repeat: 9;
    --header-component-image-start: 5;
    --header-component-image-end: 6;
    /* Footer Component */
    --footer-width-container-size: 100%;
    --footer-height-container-size: 9rem;
    --fotter-image-width: 100%;
    --fotter-image-height: 9rem;
    --footer-grid-position: 3/8;
    /* Block Component */
    --block-width-container-size: 100%;
    --block-height-container-size: 10rem;
    /* Slider Component */
    --col1-start: 10em;
    --col1-endcol2-start: 8em;
    --col2-endcol3-start: 9em;
    --col3-endcol4-start: 14em;
    --col4-endcol5-start: 12em;
    --col5-endcol6-start: 10em;
    --col6-endcol7-start: 8em;
    --col7-endcol8-start: 7em;
    --col8-endcol9-start: 12em;
    --row1-start: 5em;
    --row1-endrow2-start: 4em;
    --row2-endrow3-start: 6em;
    --row3-endrow4-start: 13em;
    --row4-endrow5-start: 6em;
    --row5-endrow6-start: 9em;
    --row6-endrow7-start: 5em;
    --row7-endrow8-start: 6em;
    --row8-endrow9-start: 4em;
    /* Colors */
    --black-background: #000000;
    
    /* Fonts */
    --font-bold: "poppins-bold";
    --font-bold-italic: "poppins-bold-italic";
    --font-light: "poppins-light";
}



/*
    Styles Components
*/

.main-layout {
    display: grid;
    min-height: 100dvh;
    grid-template-rows: auto 1fr auto;
}




