.container-image-gallery{
    grid-column: 2 / 4;
    grid-row: 6 / 7;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: 1em;
    padding: 10% 10% 0 10%;
}

.container-image-gallery img{
    border-radius: 15px;
    border: 5px solid;
}
.container-image-gallery img:hover{
    cursor: pointer;
    transition: transform 0.3s ease-in-out; 
    transform: scale(1.3, 1.3); 
}

.container-image-season {
    display: flex;
    justify-content: center;
}

.image_2, .image_18, .image_26 {
    grid-area: 5 / 2;
    width: 80%;
    height: 70%;
    align-content: center;
    margin: 8% 0 0 0;
}