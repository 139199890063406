.emissions-container {
    align-content: center;
    background-image: url('../../utils/images/components/fondo_two_components.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1%;
}

.title-emissions {
    width: 35%;
    justify-items: center;
    height: 4rem;
    margin: 0% 0 3% 33%;
    background-color: rgba(220, 53, 69, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 900;
    align-content: center;
    font-family: var(--font-bold-italic);
}