.are-container {
    // Template
    display: grid;
    grid-template-columns: [col1-start] 8rem [col1-end col2-start] 1fr [col2-end col3-start] 1fr [col3-end col4-start] 1fr [col4-end col5-start] 8rem [col5-end];
    grid-template-rows: [row1-start] 5rem [row1-end row2-start] 5rem [row2-end row3-start] 20rem [row3-end row4-start] 1fr [row4-end row5-start] 5rem [row5-end];
    // Styles
    background-image: url('../../utils/images/components/homeBkg.png');
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
}

.background-color {
    grid-column: 2 / 5;
    grid-row: 2 / 5;
    background-color: rgba(255, 255, 255, $alpha: 1);
}

.lateral-images-left {
    // Position
    grid-column: 1 / 2;
    grid-row: 1 / 6;
    // Styles
    width: 100%;
}

.lateral-images-rigth   {
    // Position
    grid-column: 5 / 6;
    grid-row: 1 / 6;
    // Styles
    width: 100%;
    transform: scaleX(-1);
}

.logo {
    // Position
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    // Styles
    width: 100%;
    justify-self: center;
}

.periferia {
    // Position
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    // Styles
    width: 50%;
    justify-self: center;
}

.recorder {
    // Position
    grid-column: 4 / 5;
    grid-row: 3 / 4;
    // Styles
    width: 60%;
    justify-self: center;
}

.logo, .periferia, .recorder {
    margin: 3rem 0 0 0;
}

.text {
    // Position
    grid-column: 2 / 5;
    grid-row: 4 / 6;
    // Styles
    justify-items: center;
    padding: 0 15% 0 15%;
    font-size: 1.3em;
}

.title {
    //  Position
    grid-column: 3 / 4;
    grid-row: 1 / 3;
    // Styles
    width: 90%;
    justify-self: center;
    justify-items: center;
    height: 4rem;
    margin-top: 17%;
    background-color: rgba(25, 135, 84, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: 900;
    font-family: var(--font-bold-italic);
}

.article-container-are {
    justify-items: center;
    font-family: var(--font-light);
}

.titles-are-mission, .titles-are-vision, .titles-are-targets {
    font-family: var(--font-bold);
    justify-self: start;
}

.titles-are-mission {
    color: rgba(0, 125, 210, $alpha: 1);
}

.titles-are-vision {
    color: rgba(223, 2, 9, $alpha: 1);
}

.titles-are-targets {
    color: rgba(75, 170, 43, $alpha: 1);
}

.image-photo {
    margin: 4% 0 4% 0
}
