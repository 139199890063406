.footer-container {
    width: var(--footer-width-container-size);
    // height: var(--footer-height-container-size);
    height: 10rem;
    background-color: var(--black-background);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

// .footer-image {
//     width: var(--fotter-image-width);
//     height: var(--fotter-image-height);
//     grid-column: var(--footer-grid-position);
// }



.footer-image_alcaldia {
    grid-column: 1/2;
    display: flex;
    justify-content: center;
}
.footer-image_pda {
    grid-column: 2/3;
    display: flex;
    justify-content: center;
}

.footer-image_alcaldia img, 
.footer-image_pda img {
    width: 50%;
    height: 50%;
    align-self: center;
}