.header-container {
    background-image: url('../../utils/images/components/header.png');
    width: var(--header-width-container-size);
    height: var(--header-height-container-size);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: var(--header-component-background-position);
}

.header-section-container {
    display: grid;
    grid-template-columns: repeat(var(--header-component-image-repeat), 1fr);
    padding-top: var(--header-component-section-paddin);
}

.header-section-image {
    width: var(--header-component-image-width);
    height: var(--header-component-image-height);
    grid-column: var(--header-component-image-start) / var(--header-component-image-end);
}

.header-section-image:hover {
    cursor: pointer;
}

.header-image_cultura {
    grid-column: 2/3;
    display: flex;
    justify-content: center;
}