.container-carousel {
    width: 100%;
    height: 70rem;
    display: flex;
    justify-content: center;
}

.container-carousel-background {
    display: grid;
    height: 57rem;
    width: 75%;
    grid-template-columns: 
    [col1-start] var(--col1-start) 
    [col1-end col2-start] var(--col1-endcol2-start) 
    [col2-end col3-start] var(--col2-endcol3-start) 
    [col3-end col4-start] var(--col3-endcol4-start) 
    [col4-end col5-start] var(--col4-endcol5-start) 
    [col5-end col6-start] var(--col5-endcol6-start) 
    [col6-end col7-start] var(--col6-endcol7-start) 
    [col7-end col8-start] var(--col7-endcol8-start) 
    [col8-end col9-start] var(--col8-endcol9-start) 
    [col9-end];
    grid-template-rows: 
    [row1-start] var(--row1-start) 
    [row1-end row2-start] var(--row1-endrow2-start) 
    [row2-end row3-start] var(--row2-endrow3-start) 
    [row3-end row4-start] var(--row3-endrow4-start) 
    [row4-end row5-start] var(--row4-endrow5-start) 
    [row5-end row6-start] var(--row5-endrow6-start) 
    [row6-end row7-start] var(--row6-endrow7-start) 
    [row7-end row8-start] var(--row7-endrow8-start) 
    [row8-end row9-start] var(--row8-endrow9-start) 
    [row9-end];
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.background-image {
    z-index: 1;
    grid-column: 1 / 10;
    grid-row: 1 / 10;
    width: 85%;
    height: 85%;
    justify-self: center;
}

.antenna {
    z-index: 2;    
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
}

.image-antenna {
    margin: 9% 0 0 21%;
}

.matera {
    z-index: 2;   
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    display: flex;
    justify-content: center;
}

.image-matera {
    width: 60%;
    margin: 9% 0% 0% 10%;
}

.sing {
    z-index: 2;   
    grid-column: 2 / 4;
    grid-row: 4 / 5;
    display: flex;
    justify-content: center;
}

.image-sing {
    width: 50%;
    margin: 0 0 0 22%;
}

.plants {
    z-index: 2;   
    grid-column: 2 / 4;
    grid-row: 7 / 9;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.image-plants {
    margin: 17% 0 0 0;
    width: 50%;
}

.guessChair {
    z-index: 2;   
    grid-column: 2 / 5;
    grid-row: 6 / 9;
    display: flex;
    justify-content: center;
}

.image-guessChair {
    width: 55%;
    margin: 0 0 0 10%;
}

.chair {
    z-index: 2;   
    grid-column: 6 / 8;
    grid-row: 6 / 9;
    display: flex;
    justify-content: center; 
}

.image-chair {
    width: 50%;
    margin: 0 0 23% 25%;
}

.record {
    z-index: 2;   
    grid-column: 4 / 6;
    grid-row: 6 / 8;
    display: flex;
    justify-content: center;
}

.image-record {
    width: 30%;
    margin: 10% 0 0 40%;
}

.magaphone {
    z-index: 0;
    grid-column: 8 / 10;
    grid-row: 3 / 5;
    display: flex;
    justify-content: center;
}

.image-magaphone {
    width: 35%;
    margin: 0 30% 0 0;
}

.guitar {
    z-index: 2;
    grid-column: 7 / 9;
    grid-row: 3 / 5;
    display: flex;
    justify-content: center;
}

.image-guitar {
    width: 20%;
    margin: 35% 0 0 35%;
}


.boot {
    z-index: 2;   
    grid-column: 7 / 9;
    grid-row: 7 / 9;
    display: flex;
    justify-content: center;
}

.image-boot {
    width: 30%;
    margin: 0 0 20% 25%;
}


.image-antenna:hover,
.image-matera:hover,
.image-sing:hover,
.image-plants:hover,
.image-guessChair:hover,
.image-chair:hover,
.image-record:hover,
.image-magaphone:hover,
.image-guitar:hover,
.image-boot:hover {
    cursor: pointer;
    // transition: transform 0.5s ease-in-out; 
    transform: scale(1.2, 1.2); 
}