.gallery-container {
    align-content: center;
    background-image: url('../../utils/images/components/fondo_two_components.jpg');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1%;
    margin: 0% 0 10% 0;

    display: grid;
    grid-template-columns: 10rem 1fr 1fr 10rem;
    grid-template-rows: 10rem auto auto auto auto;
    // grid-template-rows: repeat(5, auto);
}

.title-gallery {
    // Position
    grid-column: 2 / 4;
    grid-row: 1 / 1;
    display: flex;
    justify-content: center;
    justify-self: center;
    // Styles
    width: 35%;
    justify-items: center;
    height: 4rem;
    background-color: rgba(13, 110, 253, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 900;
    align-content: center;
    font-family: var(--font-bold-italic);
}

.radio-text {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // Styles
    height: 18rem;
    padding: 0 10% 0 10%;
    font-family: var(--font-light);
    color: rgba(255, 255, 255, $alpha: 1);
}

.radio-text h2, .season-text h4 {
    align-self: center;
    font-family: var(--font-bold);
}

.radio-text p, .season-text p,
.radio-text h2, .season-text h4 {
    font-size: 1.3rem;
}

.images-container {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    display: flex;
    justify-content: space-evenly;
    height: 15rem;
    padding: 0 4% 0 4%;
    margin-top: 4%;
}

.seasons-container {
    grid-column: 2 / 4;
    grid-row: 4 / 5;
    display: flex;
    justify-content: space-evenly;
    height: 14rem;
    padding-top: 4%;
}

.season-text{
    grid-column: 2 / 4;
    grid-row: 5 / 6;
    height: 18rem;
    padding: 0 10% 0 10%;
    font-family: var(--font-light);
    color: rgba(255, 255, 255, $alpha: 1);
}

.container-season-1 p,
.container-season-2 p,
.container-season-3 p {
    margin: 6.5% 0 0 1.5%;
    position: absolute;
    font-family: var(--font-bold);
    color: rgba(255, 255, 255, $alpha: 1);
}

.container-season-1 img, 
.container-season-2 img,
.container-season-3 img {
    width: 10rem;
}

.container-season-1 p:hover, 
.container-season-2 p:hover,
.container-season-3 p:hover {
    cursor: pointer;
    transition: transform 0.1s ease-in-out; 
    transform: scale(1.2, 1.1); 
}