.audiomultiplayer-container {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    align-content: center;
}

.audio-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-self: center;
    // Styles
    width: 65%;
    height: 42rem;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 0 4% 0;
    border-radius: 15px;
    align-items: center;
    flex-direction: column;
}

.audio-image {
    width: 70%;
    height: 31rem;
    border-radius: 15px;
    margin-bottom: 2%;
}

.tile-audio {
    color: rgb(255, 255, 255);
    font-size: 1.8rem;
    margin: 0 0 2% 15%;
    align-self: start;
    font-family: var(--font-light);
}

.audio {
    width: 70%;
}