.container-routing {
    display: flex;
    justify-content: center;
    height: 82rem;
}

.container-content {
    display: grid;
    grid-template-columns: [col1-start] 3% [col1-end col2-start] 1fr [col2-end col3-start] 1fr [col3-end col4-start] 3% [col4-end];
    grid-template-rows: [row1-start] auto [row1-end row2-start] 1fr [row2-end row3-start] auto [row3-end row4-start] 1fr [row4-end];
    width: 78%;
    height: 70vh;
    margin: 0 0 5% 0;
}


/* Emissions */
.sign-emissions {
    // Position
    grid-column: 2 / 2;
    grid-row: 1 / -2;
    // Styles
    width: 30%;
    display: flex;
    justify-self: center;
    justify-content: center;
    margin: 2% 0 0 0;
    position: relative;
    height: 3.5rem;
    background-color: rgba(220, 53, 69, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: bolder;
    font-family: var(--font-bold);
}

.emissions-content {
    // Position
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    // Styles
    margin: 20% 0 0 0;
}

/* Emissions Grid*/
.article-container {
    display: grid;
    // grid-template-columns: [col1-start] 1fr [col1-end col2-start] 1fr [col2-end];
    // grid-template-rows: [row1-start] 1fr [row1-end row2-start] 1fr [row2-end];
    grid-template-columns: repeat(3, 1fr);
}

.article-item-1 {
    grid-column: 1 / 2;
    // grid-row: 1 / 2;
}

.article-item-1 p {
    color: rgba(13, 110, 253, $alpha: 1);
}

.article-item-2 {
    grid-column: 2 / 3;
    // grid-row: 1 / 2;
}

.article-item-2 p {
    color: rgba(220, 53, 69, $alpha: 1);
}

.article-item-3 {
    grid-column: 3 / 4;
    // grid-row: 2 / 3;
}

.article-item-3 p {
    color: rgba(25, 135, 84, $alpha: 1);
}

.article-item-1 p, 
.article-item-2 p,
.article-item-3 p {
    font-family: var(--font-bold);
}

.article-item-1 ul, 
.article-item-2 ul,
.article-item-3 ul {
    list-style: none;
    font-family: var(--font-light);
}

.article-container div {
    justify-self: center;
}

/* Gallery */
.sign-gallery {
    // Position
    grid-column: 3 / 3;
    grid-row: 1 / -2;
    // Styles
    width: 30%;
    display: flex;
    justify-self: center;
    justify-content: center;
    margin: 2% 0 0 0;
    position: relative;
    height: 3.5rem;
    background-color: rgba(13, 110, 253, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: bolder;
    font-family: var(--font-bold);
}

.gallery-content {
    // Position
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    // Styles
    justify-self: center;
    margin-top: 16%;
    margin-bottom: 5%;
}

/* Videos */
.sign-videos {
    // Position
    grid-column: 2 / 2;
    grid-row: 3 / 4;
    // Styles
    width: 30%;
    display: flex;
    justify-self: center;
    justify-content: center;
    margin: 2% 0 0 0;
    position: relative;
    height: 3.5rem;
    background-color: rgba(255, 193, 7, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: bolder;
    font-family: var(--font-bold);
}

.videos-content {
    // Position
    grid-column: 2 / 2;
    grid-row: 4 / 4;
    // Styles
    justify-self: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

/* Are */
.sign-are {
    // Position
    grid-column: 3 / 3;
    grid-row: 3 / 4;
    // Styles
    width: 30%;
    display: flex;
    justify-self: center;
    justify-content: center;
    margin: 2% 0 0 0;
    position: relative;
    height: 3.5rem;
    background-color: rgba(25, 135, 84, $alpha: 1);
    box-shadow: -5px 5px 0 0 rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: bolder;
    font-family: var(--font-bold);
}

.are-content {
    // Position
    grid-column: 3 / 3;
    grid-row: 4 / 4;
    // Styles
    font-size: 1.3rem;
    margin: 5% 0 0 0;
}

.are-content p {
    font-family: var(--font-light);
}

/* Background */

.background {
    background-color: rgba(255, 255, 255, $alpha: 0.5);
    grid-column: 2 / 4;
    grid-row: 2 / 6;
    margin-top: 4%;
    border-radius: 20px;
}

.sign-emissions:hover, 
.sign-gallery:hover, 
.sign-videos:hover, 
.sign-are:hover {
    cursor: pointer;
    transition: transform 0.5s ease-in-out; 
    transform: scale(1.2, 1.1); 
}

.sign-emissions, 
.sign-gallery,
.sign-videos, 
.sign-are {
    font-family: var(--font-bold);
    align-items: center;
    margin: unset;
}