.videosmultiplayer-container {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    align-content: center;
    width: 80%;
    justify-content: center;
    justify-self: center;
    margin: 0 0 10% 0;
    border-radius: 15px;
    padding: 3% 0 3% 0;
    background: rgba(255, 255, 255, $alpha: 0.2);
}

.video-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    width: 80%;
    height: 42rem;
    margin: 0 0 4% 0;
    border-radius: 15px;
    align-items: center;
}

.title-video {
    color: rgb(255, 255, 255);
    font-size: 1.8rem;
    margin: 0 0 2% 0;
    align-self: start;
    font-family: var(--font-bold);
}

.description-video {
    color: rgb(255, 255, 255);
    font-family: var(--font-light);
    margin: 0 0 3% 0;
}